/* eslint-disable react/self-closing-comp */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Animated } from 'react-animated-css';
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaWhatsapp,
} from 'react-icons/fa';

import Container from './Container';
import TitleCard from './TitleCard';
import { PhotoCover } from './PhotoCover';
import ContactForm from './ContactsContent/ContactForm';

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 5px;
`;

const TextWrapper = styled.div`
  color: #fff;
  flex: 1 1 100%;
  & p {
    text-align: center;
  }
  @media (max-width: 1200px) {
    flex: 1 1 100%;
    padding: 0 0 10px 0;
  }
`;

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      contacts: file(relativePath: { eq: "contact-us.jpg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className="mx-auto pb-10">
      <PhotoCover src={data.contacts.publicURL} />

      <div className="pt-1">
        <Animated animationIn="fadeInDown" animationInDuration={1400}>
          <TitleCard title="Contact us" />
        </Animated>
      </div>
      <Container>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-blue-600 rounded-tl rounded-tr">
          <Card>
            <FaEnvelope className="text-3xl text-white" />
            <TextWrapper>
              <p className="m-0 uppercase text-md font-semibold">email us</p>
              <p className="m-0">Sales@metalsvalley.com</p>
            </TextWrapper>
          </Card>
          <Card>
            <FaPhone className="text-3xl text-white" />
            <TextWrapper>
              <p className="m-0 uppercase text-md font-semibold">Telephone</p>
              <p className="m-0 font-sans">00971 6 7314159</p>
            </TextWrapper>
          </Card>
          <Card>
            <FaWhatsapp className="text-3xl text-white" />
            <TextWrapper>
              <p className="m-0 uppercase text-md font-semibold">
                Mobile/WhatsApp
              </p>
              <p className="m-0 font-sans">00971 56 64 66401</p>
            </TextWrapper>
          </Card>
          <Card>
            <FaMapMarkerAlt className="text-3xl text-white" />
            <TextWrapper>
              <p className="m-0 uppercase text-md font-semibold">address</p>
              <p className="m-0 text-left border-box text-sm">
                Business center, Al Shmookh
                <br />
                building, UAQ, UAE
              </p>
            </TextWrapper>
          </Card>
        </div>
        <form
          data-netlify="true"
          hidden
          name="contact"
          netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
        >
          <input type="text" name="name" />
          <input type="email" name="email" />
          <input type="text" name="subject" />
          <textarea name="message"></textarea>
          <input name="bot-field" type="hidden" />
        </form>
        <ContactForm />
      </Container>
    </section>
  );
};
export default Contact;
