import React, { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { InputText, Label, Row, InputArea, Warning } from './FormInputs';
import Loading from './Loading';

const ValidateForm = Yup.object().shape({
  name: Yup.string().trim().min(4).max(50).label('Name').required(),
  email: Yup.string().trim().email().label('Email').required(),
  subject: Yup.string().trim().nullable().min(5).label('Subject'),
  message: Yup.string().trim().nullable().min(5).max(250).label('Message'),
});

const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const ContactForm = () => {
  const recaptchaKey = process.env.GATSBY_MY_RECAPTCHA_KEY || 'nokeys';

  const recaptchaRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isVerify, setVerify] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <Formik
        initialValues={{
          'form-name': 'contact',
          'bot-field': '',
          name: '',
          email: '',
          subject: '',
          message: '',
        }}
        validationSchema={ValidateForm}
        onSubmit={(values, actions) => {
          if (
            values &&
            recaptchaRef.current &&
            recaptchaRef.current.getValue()
          ) {
            const recaptchaValue =
              (recaptchaRef.current && recaptchaRef.current.getValue()) || null;
            const bodyValues = encode({
              ...values,
              'g-recaptcha-response': recaptchaValue,
            });
            setLoading(true);
            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: bodyValues,
            })
              .then(() => {
                actions.resetForm();
                recaptchaRef.current.reset();
                setVerify(false);
                setLoading(false);
              })
              .catch(err => alert(err))
              .finally(() => {
                actions.setSubmitting(false);
                setLoading(false);
              });
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <Form name="contact" className="m-0 py-8 px-2 md:px-0">
            <Field type="hidden" name="form-name" />
            <Field type="hidden" name="bot-name" />
            <div className=" grid grid-cols-1 col-gap-8 md:grid-cols-2 ">
              <div>
                <Row>
                  <Label>
                    name
                    <span className="text-red-600">*</span>
                  </Label>
                  <Field
                    value={values.name}
                    component={InputText}
                    name="name"
                    autoComplete="off"
                  />
                  <ErrorMessage component={Warning} name="name" />
                </Row>
                <Row>
                  <Label>email*</Label>
                  <Field
                    value={values.email}
                    component={InputText}
                    name="email"
                    autoComplete="off"
                  />
                  <ErrorMessage component={Warning} name="email" />
                </Row>
                <Row>
                  <Label>Subject</Label>
                  <Field
                    value={values.subject}
                    component={InputText}
                    name="subject"
                    autoComplete="off"
                  />
                  <ErrorMessage component={Warning} name="subject" />
                </Row>
              </div>
              <div>
                <Row>
                  <Label>Message</Label>
                  <Field
                    value={values.message}
                    component={InputArea}
                    name="message"
                    autoComplete="off"
                  />
                  <ErrorMessage component={Warning} name="message" />
                </Row>
              </div>
            </div>
            <div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaKey}
                onChange={() => setVerify(true)}
              />
            </div>
            <button
              type="submit"
              disabled={!isVerify}
              onClick={handleSubmit}
              className={`${
                !isVerify ? 'bg-blue-300 ' : 'bg-blue-600 '
              }text-white py-2 px-8 mt-2 text-xl rounded`}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
